import styled from '@emotion/styled';
import type { LinkProps } from 'react-router-dom';
import { AnalysisTable } from '@/screens/Project.QuantAnalysis/Analysis.Table';
import { SeeMore, Expander, Collapser } from '@/brand-insights/components/presentation';
import type { Chat } from '@/brand-insights/types';
import { MessageCitationMaybeLink } from './Citation.MaybeLink';

type Props = {
    className?: string;
    value: Chat.CitationSnippetMetadataQuantAnalysis;
    to: LinkProps['to'];
};

export const QuantAnalysesCitation = ({ className, value, to }: Props) => {
  return (
    <Root className={className}>
      <Wrap>
        <SeeMore
                  ExpanderComp={SeeMoreExpander}
                  CollapserComp={SeeMoreCollapser}
                  lineHeight={SnippetLineHeight}
                  maxLines={15}>
          <Text>
            {value.footer.join('\n')}
          </Text>
          <br /> <br />
          <TableWrapper>
            <AnalysisTable data={value.table} />
          </TableWrapper>
        </SeeMore>
        {!!to && (
          <Footer>
            <MessageCitationMaybeLink to={to}>
              <View>{`View Analysis >`}</View>
            </MessageCitationMaybeLink>
          </Footer>
        )}
      </Wrap>
    </Root>
  );
};

const SnippetLineHeight = 18;

const Root = styled.div`
  box-sizing: border-box;
  padding-left: 4px;
  background-color: ${props => props.theme.palette.gray.light3};
  padding: 10px;
`;

const Wrap = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: column;
`;

const Text = styled.span`
    color: black;
    font-size: 13px;
  `;

const Footer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;

const View = styled.div`
  font-size: 13px;
  font-family: ${props => props.theme.fonts.semiBold};;
`;

const SeeMoreExpander = styled(Expander)`
  background-color: ${props => props.theme.palette.gray.light3} !important;
`;

const SeeMoreCollapser = styled(Collapser)`
  background-color: ${props => props.theme.palette.gray.light3} !important;
`;

const TableWrapper = styled.div`
  overflow-x: auto;
  max-width: 100%;
`;