/** @jsxImportSource @emotion/react */
import { memo } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useTheme } from '@/brand-insights/components/Theme';
import { Tooltip } from '@/brand-insights/components/presentation';
import { ShareChat } from '@/components/icons/ShareChat';
import { BaseMessageButton } from './Message.Button';

type Props = {
    className?: string;
    onClick: () => unknown;
    color?: string;
};

const size = 16;

export const ShareChatThread = memo(({ className, onClick, color }: Props) => {

  const theme = useTheme();

  return (
    <Tooltip title={`Share chat thread`}>
      <Root className={className} onClick={onClick}>
        <StyledShareIcon
         css={css({
           color: 'transparent',
           stroke: color ?? theme.palette.primary.light6,
         })}
         size={size} />
      </Root>
    </Tooltip>
  );
});

export const ShareButton = memo(({
  className,
  onClick,
}: Props) => {

  return (
    <BaseMessageButton className={className} onClick={onClick}>
      <StyledShareIcon size={size} />
      <ButtonShare>
        Share
      </ButtonShare>
    </BaseMessageButton>
  );
});

const ButtonShare = styled.div({
  marginLeft: 5,
});

const Root = styled.div`
  cursor: pointer;
  transition: 0.3s;
`;

const StyledShareIcon = styled(ShareChat)(({ theme }) => ` 
  font-size: 18px !important;
`);
